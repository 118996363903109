<template>
  <div>
    <div class="navigation-wrapper">
      <div class="navigation-row">
        <div class="navigation-col">
          <p class="navigation-title text-black at-dark">{{ optionValue.title }}</p>
        </div>

        <div class="navigation-col">
          <div class="navigation">
            <BaseButton class="navigation-button"
              v-for="( option, index ) of optionsFiltered"
              v-bind:key="index"
              v-bind:class="{
                primary: ( option === optionValue ),
                'border-left-none': ( index !== 0 ),
                'border-right-none': ( index < ( options.length - 1 ) ),
                'border-left-transparent': ( index > 0 ),
                'border-right-transparent': ( options[ index + 1 ] === optionValue )
              }"
              v-on:click="optionValue = option"
            >
              <span class="at-dark"
                v-bind:class="{ 'text-black': option !== optionValue }"
              >{{ option.text }}</span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <component
        v-bind:is="tabComponent"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import BaseButton from '@/components/Base/Button'
  import TabFlat from './Tab/Flat'
  import TabParking from './Tab/Parking'
  import TabOffice from './Tab/Office'
  import { options } from './state'

  const optionValue = ref( options.value[ 0 ] )

  const optionsFiltered = computed( () => {
    return ( options.value.length > 1 )
      ? options.value
      : []
  } )

  const tabComponent = computed( () => {
    if ( optionValue.value?.slug === 'flat' ) return TabFlat
    if ( optionValue.value?.slug === 'parking' ) return TabParking
    if ( optionValue.value?.slug === 'office' ) return TabOffice
  } )
</script>