import { openByPropertyId } from '@/views/Modal/module'
import { APIRoot, accessToken } from '../backend/config'

const constructor = payload => {
  return {

    get id() {
      return payload.id
    },

    get roomsAmount() {
      return payload.rooms_amount
    },

    get price() {
      return payload.price.value
    },

    get area() {
      return Number( payload.area.area_total )
    },

    get floor() {
      return Number( payload.floor )
    },

    get preset() {
      return payload.preset
    },

    get number() {
      return payload.number
    },

    get bathroomAmount() {
      return ( payload?.attributes?.combined_bathroom_count + payload?.attributes?.separated_bathroom_count )
    },

    get type() {
      return payload.propertyType
    },

    get houseId() {
      return payload.house_id
    },

    get isStudio() {
      return payload.studio
    },

    get status() {
      return payload.status
    },

    show() {
      openByPropertyId( this.id )
    },

    leaveApplication() {
      const event = new CustomEvent( 'leaveapplicationproperty', { detail: this } )
      document.dispatchEvent( event )
    },

    downloadPlan() {
      const link = document.createElement( 'a' )
      link.href = `${ APIRoot }/pdf/${ this.id }?access-token=${ accessToken }`
      link.target = '_blank'
      document.body.appendChild( link )
      link.click() 
      link.remove()
    }
  }
}

export default constructor